import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { media } from '@/utils/mixin';

import { MenuProps } from './Menu.types';

function Menu({ data, type, isMenuOpened, onMenuOpened }: MenuProps) {
  return (
    <>
      <List type={type} isMenuOpened={isMenuOpened}>
        {data.map(({ label, link }, index) => (
          <ListItem key={index} type={type} isMenuOpened={isMenuOpened}>
            <ListLinkWrapper onClick={onMenuOpened}>
              <ListLink to={link} type={type} isMenuOpened={isMenuOpened}>
                {label}
              </ListLink>
            </ListLinkWrapper>
          </ListItem>
        ))}
      </List>
      <Burger onClick={onMenuOpened}>
        <Icon isMenuOpened={isMenuOpened} />
      </Burger>
    </>
  );
}

export default Menu;

const List = styled.ul<{
  type: MenuProps['type'];
  isMenuOpened: MenuProps['isMenuOpened'];
}>`
  display: flex;

  ${({ type }) =>
    type === 'header' &&
    css`
      ${media.tablet(css`
        display: none;
      `)}
    `};

  ${({ type, isMenuOpened }) =>
    type === 'header' &&
    isMenuOpened &&
    css`
      ${media.tablet(css`
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: black;
        z-index: -1;
        flex-direction: column;
      `)}
    `};

  ${({ type }) =>
    type === 'footer' &&
    css`
      ${media.tablet(css`
        display: flex;
        flex-direction: column;
        margin-top: 40px;
      `)}
    `};
`;

const ListItem = styled.li<{
  type: MenuProps['type'];
  isMenuOpened: MenuProps['isMenuOpened'];
}>`
  margin-left: 115px;

  &:nth-child(1) {
    margin-left: 0;
  }

  ${({ type, isMenuOpened }) =>
    type === 'header' &&
    isMenuOpened &&
    css`
      ${media.tablet(css`
        margin-top: 100px;

        &:not(:first-child) {
          margin-top: 30px;
        }

        &:nth-child(n) {
          margin-left: 20px;
        }
      `)}
    `};

  ${({ type }) =>
    type === 'footer' &&
    css`
      ${media.tablet(css`
        margin-left: 0;
        margin-top: 15px;

        &:nth-child(1) {
          margin-top: 0;
        }
      `)}
    `};
`;

const ListLinkWrapper = styled.span``;

const ListLink = styled(Link)<{
  type: MenuProps['type'];
  isMenuOpened: MenuProps['isMenuOpened'];
}>`
  ${({ type }) =>
    type === 'header' &&
    css`
      font-size: 14px;
      line-height: 21px;

      ${media.tablet(css`
        font-size: 22px;
        line-height: 27px;
      `)}
    `};

  ${({ type }) =>
    type === 'footer' &&
    css`
      font-size: 16px;
      line-height: 24px;
    `};

  border-bottom: 1px solid transparent;
  color: #ffffff;

  &:hover {
    border-bottom: 1px solid white;
  }

  ${media.tablet(css`
    &:hover {
      border-bottom: 1px solid transparent;
    }
  `)};
`;

const Burger = styled.button`
  width: 24px;
  height: 24px;
  display: none;

  ${media.tablet(css`
    display: block;
  `)};
`;

const Icon = styled.i<{ isMenuOpened: MenuProps['isMenuOpened'] }>`
  height: 2px;
  border-radius: 20px;
  background: white;
  transition: all 0.25s ease-in-out;
  width: 24px;
  display: block;
  position: relative;
  margin: 4px 0;

  &:before,
  &:after {
    content: '';
    height: 2px;
    background: white;
    border-radius: 20px;
    position: absolute;
    transition: all 0.25s ease-in-out;
    left: 0;
    right: 0;
  }

  &:before {
    top: -6px;
  }

  &:after {
    bottom: -6px;
  }

  ${({ isMenuOpened }) =>
    isMenuOpened &&
    css`
      background: transparent;

      &:before {
        transform: translate(-1px, 6px) rotate(-45deg);
        left: 2px;
      }

      &:after {
        transform: translate(-1px, -6px) rotate(45deg);
        left: 2px;
      }
    `};
`;
