import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import Menu from '@/components/Menu/Menu';
import Logo from '@/components/Logo';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';

function Header() {
  const headerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];

  const [isMenuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    document.body.style.overflow =
      isMenuOpened && window.innerWidth < 1260 ? 'hidden' : 'auto';
  }, [isMenuOpened]);

  const handleMenuOpened = () => {
    setMenuOpened(!isMenuOpened);
  };

  const handleMenuClose = () => {
    setMenuOpened(false);
  };

  return (
    <HeaderContainer>
      <ContentContainer>
        <HeaderInner>
          <Logo header onMenuClose={handleMenuClose} />
          <Menu
            data={headerMenu}
            type="header"
            isMenuOpened={isMenuOpened}
            onMenuOpened={handleMenuOpened}
          />
        </HeaderInner>
      </ContentContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 80px;
  z-index: 3;
  border-bottom: 1px solid rgba(256, 256, 256, 0.2);
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Header;
