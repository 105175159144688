import styled from 'styled-components';

const ContentContainer = styled.div`
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

export default ContentContainer;
