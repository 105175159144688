import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';
import Menu from '@/components/Menu/Menu';
import Logo from '@/components/Logo';
import useSettingItem from '@/hooks/useSettingItem';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { media } from '@/utils/mixin';

function Footer() {
  const footerMenu =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  const [coop, address, email] = [
    useSettingItem('FOOTER_COPYRIGHT'),
    useSettingItem('FOOTER_ADDRESS'),
    useSettingItem('FOOTER_EMAIL'),
  ];

  return (
    <FooterContainer>
      <ContentContainer>
        <FooterInner>
          <FooterTop>
            <Logo footer />
            <Menu data={footerMenu} type="footer" />
          </FooterTop>
          <FooterBottom>
            <Copyright>
              {coop} {address}
            </Copyright>
            <Email to="mailto:info@ddsn.ae">{email}</Email>
          </FooterBottom>
        </FooterInner>
      </ContentContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  flex: 0 0 400px;
  background: black;
  padding: 67px 0;
`;

const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.tablet(css`
    flex-direction: column;
    align-items: flex-start;

    & > ul {
      display: flex;
    }

    & > button {
      display: none;
    }
  `)}
`;

const FooterBottom = styled.div`
  width: 205px;
  margin-top: 70px;

  ${media.tablet(css`
    margin-top: 50px;
  `)}
`;

const Copyright = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
`;

const Email = styled(Link)`
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  line-height: 24px;

  &:hover {
    color: white;
  }
`;

export default Footer;
