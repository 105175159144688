import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';
import HLogo from '@/assets/svg/ddsn-header-logo.svg';
import FLogo from '@/assets/svg/ddsn-footer-logo.svg';
import Link from '@/components/Link';

import { LogoProps } from './Logo.types';

function Logo({ header, onMenuClose }: LogoProps) {
  return (
    <div onClick={onMenuClose}>
      <LogoLink to="/">
        {header ? <HeaderLogo src={HLogo} /> : <FooterLogo src={FLogo} />}
      </LogoLink>
    </div>
  );
}

export default Logo;

const HeaderLogo = styled(Picture)`
  width: 210px;
  height: 32px;
`;

const FooterLogo = styled(Picture)`
  width: 186px;
  height: 60px;
`;

const LogoLink = styled(Link)``;
